.table-responsive{
    width: 80%;
    z-index: 1;
    text-align: center;
    overflow: hidden;

    align-items: center;
}

.table-bordered td, .table-bordered th{
    background-color: var(--second-color);
    width: 50%;
    font-size: 20px;
    vertical-align: middle;

    font-family: "Bungee";
}

.table-bordered th {
    background-color: #a0a3a7;
    font-size: 34px;
}


.background-opacity{
    background: var(--main-color);
    height: 100%;
    width: 100%;
    position: absolute;
    opacity: 0.75;
}

.background-prestations{
    justify-content: center;
    display: flex;
    position: relative;
    flex-direction: column;

    background: url('../assets/background_real.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;

    height: 75vh;
    align-items: center;
}

.card-body-custom{
    background-color: #a0a3a7;
    text-align: center;
    box-shadow: 0 4px 8px 0 rgba(255, 255, 255, 0.2), 0 6px 20px 0 rgba(255, 255, 255, 0.19);
}

.card-title-custom{
    font-family: 'Bungee';
}

.card-text-custom{
    font-size: 20px;
}

.card-custom{
    width: 18rem; 
}

.card-container{
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.prestations-title > h1{
    font-size: 40px;
    color: var(--second-color);
    z-index: 1;

    border-bottom: 1px solid var(--second-color);
}

.prestations-title{
    margin-bottom: 2%;
    z-index: 1;
}

.prestations-subtitle{
    margin-bottom: 5%;
    color: white;
}

.table thead th{
    vertical-align: middle;
}

@media only screen and (max-width: 767px) {

    .prestations-title > h1{
        border-bottom: 0;
    }

    .background-prestations{
        height: 110vh;
    }
  }