:root{
  --main-color: #18191a;
  --second-color: white;
  --third-color: #fe7103;
}

@font-face {
  font-family: "Bungee";   /*Can be any text*/
  src: local("Bungee"),
    url("./assets/fonts/Bungee-Regular.ttf") format("truetype");
}

@font-face {
font-family: "Gasoek";   /*Can be any text*/
src: local("Gasoek"),
  url("./assets/fonts/GasoekOne-Regular.ttf") format("truetype");
}

h1{
  font-family: "Bungee";
}


html{
  scroll-behavior: smooth;
}