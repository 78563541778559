.img{
    width: 300px;
    height: 320px;
}

.motto{
    font-style: italic;
    font-size: 28px;
}

.presentation{
    text-align: left;
    color: white;
}

.presentation-background{
    background-color: #18191a;
    padding-top: 5%;
    padding-bottom: 5%;
    width: 100%;
    overflow: hidden;

    text-align: center;
}

.presentation-title {
    font-size: 50px;
}

.presentation-subtitle {
    font-size: 36px;
}

.presentation-desc {
    font-size: 24px;
}

.row {
    align-items: center;
    justify-content: center;
}

@media only screen and (max-width: 767px) {
    .presentation{
        text-align: center;
    }

    .presentation-desc{
        font-size: 20px;
        padding: 3%;
    }
  }