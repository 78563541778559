
.titleContainer{
    width: 100vw;
    height: 100vh;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 1;
    color: var(--second-color);

}

h1 {
  font-size: 140px;
}

h2, .text-animation-container {
  font-family: "Bungee";
  opacity: 0.7;
  margin: 0;
}

.video-background {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
  }
  
.video-background video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1; /* Place the video behind the content */
}

.navbar {
  color: var(--second-color);
  margin-left: 70%;
  display: flex;
  position: absolute;
  height: 10vh;
  font-size: 25px;
  border-bottom: 1px solid;
  right: 10px;

  font-family: 'Gasoek';
}

.navbar-links{
  color: var(--second-color) !important;
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;

}

.navbar-links:hover{
  color: var(--third-color) !important;
}


.text-animation-container{
  display: flex;
  align-items: center;
  gap: 20px;

  font-size: 2rem;
}

@media only screen and (max-width: 767px) {
  .navbar{
    border-bottom: none;
  }

  h1{
    font-size: 60px;
    text-align: center;
  }

  h2, .text-animation-container{
    font-size: 24px;
    text-align: center;
  }

  .text-animation-container{
    flex-direction: column;
    gap: 0;
  }

  #basic-navbar-nav{
    background: var(--main-color);
  }
}