.realisations{
    display: flex;
    justify-content: center;
    flex-direction: column;

    background-color: var(--main-color);
    align-items: center;
    position: relative;

    padding-top: 3%;
}

.waves{
    background: url('../assets/wave.png');
    bottom: 0;
    position: absolute;
    width: 100%;
    height: 50vh;
    background-size: cover;
}

.realisations-title > h1{
    font-size: 40px;
    color: var(--second-color);

    border-bottom: 1px solid var(--second-color);
}

.realisations-body{
    display: flex;
    justify-content: space-evenly;

    padding-top: 3%;
    padding-bottom: 10%;
}

.realisations-ctr{
    display: flex;
    align-items: center;
    justify-content: center;
}

.realisations-img{
    width: 75%;
    box-shadow: 0 4px 8px 0 rgba(255, 255, 255, 0.2), 0 6px 20px 0 rgba(255, 255, 255, 0.19);
    z-index: 1;
}


.second-row{
    padding-top: 0;
    justify-content: center;
}

.second-row-ctr{
    width: 50%;
}

.second-row-img{
    width: 45%;
}

@media only screen and (max-width: 767px) {

    .realisations-title > h1{
        font-size: 45px;
        border: none;
    }

    .realisations-body{
        flex-direction: column;
        gap: 20px
    }

    .second-row-ctr{
        width: auto;
    }
    
    .second-row-img{
        width: 75%;
    }
    
}
