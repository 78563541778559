.background{
    justify-content: space-evenly;
    display: flex;
    background-color: var(--main-color);

    height: 75vh;
    width: 100%;
    align-items: center;
    gap: 20px;
}

.infos {
    color: var(--second-color);

    display: flex;
    flex-direction: column;
    justify-content: center;
}

.name{
    font-size: 32px;
    text-align: center;
}

.elem {
    width: 30% !important;
}

.day-table {
    text-align: center;
}

.phone, .itinary, .mail{
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.phone-btn, .mail-btn{
    width: 50px;
    height: 50px;
    border-radius: 50px;

    color: var(--third-color);
    border: 1px solid var(--third-color);
}

.phone-btn:hover, .mail-btn:hover{
    background-color: var(--third-color);
    border: 1px solid var(--third-color);
    color: var(--main-color);
}

.phone, .mail{
    border-bottom: 1px solid var(--third-color);
}

.contact-title{
    font-size: 40px;
    color: var(--second-color);
    border-bottom: 1px solid var(--second-color);
    

    margin-bottom: 0;
    text-align: center;
}

.contact-title-container{
    display: flex;
    justify-content: center;
    background-color: var(--main-color);
    padding-top: 5%;
}

@media only screen and (max-width: 767px) {
    .background{
        height: auto;
        flex-direction: column;
        padding-top: 3%;
        padding-bottom: 3%;
    }

    .elem{
        width: 90% !important;
    }

    .contact-title{
        border-bottom: 0;
    }

}
